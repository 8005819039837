import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Markdown from "react-markdown";
import Content, { HTMLContent } from "../components/Content";
import { getImage, StaticImage } from "gatsby-plugin-image";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// eslint-disable-next-line
export const AboutPageTemplate = ({
  title,
  content,
  faqs,
  bigNumbers,
  history_tt,
  history,
  teacher_tt,
  teacher,
  teacher_img,
  image,
}) => {
  const htmlContent = content ? <HTMLContent content={content} /> : <div></div>;

  return (
    <>
      <section class="services section-padding" style={{ paddingTop: "14rem" }}>
        <div class="container">
          <div class="sec-head mb-80">
            <div class="row justify-content-center about-wrap">
              <div class="col-lg-8">
                <div class="text-center">
                  <h6 class="mb-15 wow fadeInUp" data-wow-delay="0.4s">
                    // About Us
                  </h6>

                  <h1
                    class="fw-700 js-splittext-lines"
                    style={{ fontSize: "48px" }}
                  >
                    {title}
                  </h1>
                </div>

                {htmlContent}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="image-section">
        <img
          src={image.childImageSharp.gatsbyImageData.images.fallback.src}
          alt=""
        />
      </section>

      <section class="services section-padding">
        <div class="container about-wrap">
          <div class="sec-head mb-80">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="text-center">
                  <h3 class="fw-700 js-splittext-lines">{history_tt}</h3>
                </div>
              </div>
            </div>
          </div>

          <Markdown>{history}</Markdown>
        </div>
      </section>

      <section class="hero-dark bg-blck section-padding">
        <div class="container position-re">
          <div class="row">
            <div class="col-lg-6">
              <div class="cont">
                <h6 class="md-title mb-15 wow fadeInUp" data-wow-delay="0.4s">
                  // About our Professor
                </h6>
                <h2 class="js-splittext-lines">{teacher_tt}</h2>
              </div>
            </div>
            <div class="col-lg-2 d-flex justify-content-end align-items-end">
              <div class="wow zoomIn" data-wow-delay="0.4s"></div>
            </div>
          </div>
          <div class="row mt-30">
            <div class="col-lg-5">
              <div class="img mr-30">
                <img
                  src={
                    teacher_img.childImageSharp.gatsbyImageData.images.fallback
                      .src
                  }
                  alt=""
                />
              </div>
            </div>
            <div class="col-lg-5 offset-lg-1">
              <div class="text mt-30">
                <Markdown>{teacher}</Markdown>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="faqs" style={{ paddingTop: "8rem" }}>
        <div class="main-marq sub-font mb-80">
          <div class="slide-har st1">
            <div class="box non-strok">
              <div class="item">
                <h4 class="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span class="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div class="item">
                <h4 class="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span class="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div class="item">
                <h4 class="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span class="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div class="item">
                <h4 class="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span class="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div class="item">
                <h4 class="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span class="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
            </div>
            <div class="box non-strok">
              <div class="item">
                <h4 class="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span class="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div class="item">
                <h4 class="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span class="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div class="item">
                <h4 class="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span class="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div class="item">
                <h4 class="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span class="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="numbers section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="item md-mb30">
                <div>
                  <h3>{bigNumbers.no_of_exp}</h3>
                  <div className="flx">
                    <span className="icon">
                      <i className="ti-clipboard"></i>
                    </span>
                    <span className="sm-title">Years Experince</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item md-mb30">
                <div>
                  <h3>{bigNumbers.std_enrl}</h3>
                  <div>
                    <span className="icon">
                      <i className="ti-star"></i>
                    </span>
                    <span className="sm-title">Students Enrolled</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item sm-mb30">
                <div>
                  <h3>{bigNumbers.reviews}</h3>
                  <div>
                    <span className="icon">
                      <i className="ti-medall"></i>
                    </span>
                    <span className="sm-title">Reviews</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item">
                <div>
                  <h3>{bigNumbers.satisfaction}</h3>
                  <div>
                    <span className="icon">
                      <i className="ti-direction-alt"></i>
                    </span>
                    <span className="sm-title">Satisfaction</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs" style={{ paddingBottom: "8rem" }}>
        <div className="position-re">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-10">
                <div className="sec-head mb-40">
                  <h6 className="mb-15 wow fadeInUp" data-wow-delay="0.4s">
                    // Any Questions in you Mind?
                  </h6>
                  <h3 className="fw-700 mb-15 js-splittext-lines">
                    {faqs.heading}
                  </h3>
                </div>
                <Accordion>
                  {faqs.questions.map((faq, index) => (
                    <AccordionItem key={index}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          {faq.question}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{faq.answer}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
          <div className="imgs">
            <div className="img1 fit-img wow fadeIn" data-wow-delay="0.4s">
              <StaticImage src="../imgs/home/3.jpg" alt="" />
            </div>
            <div className="img2 fit-img wow fadeIn" data-wow-delay="0.6s">
              <StaticImage src="../imgs/home/2.jpg" alt="" />
            </div>
            <div className="img3 fit-img wow fadeIn" data-wow-delay="0.8s">
              <StaticImage src="../imgs/home/5.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { about_data: post, home_data } = data;

  const bigNumbers = home_data.frontmatter.bigNumbers;
  const faqs = home_data.frontmatter.faqs;

  return (
    <Layout>
      <AboutPageTemplate
        title={post.frontmatter.title}
        content={post.html}
        bigNumbers={bigNumbers}
        faqs={faqs}
        history_tt={post.frontmatter.history_tt}
        history={post.frontmatter.history}
        teacher_tt={post.frontmatter.teacher_tt}
        teacher={post.frontmatter.teacher}
        teacher_img={post.frontmatter.teacher_img}
        image={post.frontmatter.image}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage {
    about_data: markdownRemark(
      frontmatter: { templateKey: { eq: "about-page" } }
    ) {
      html
      frontmatter {
        title

        history_tt
        history

        teacher_tt
        teacher

        teacher_img {
          childImageSharp {
            gatsbyImageData
          }
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    home_data: markdownRemark(
      frontmatter: { templateKey: { eq: "index-page" } }
    ) {
      frontmatter {
        bigNumbers {
          no_of_exp
          std_enrl
          reviews
          satisfaction
        }

        faqs {
          heading
          questions {
            question
            answer
          }
        }
      }
    }
  }
`;
